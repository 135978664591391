<template>
  <div :class="itemCssClass" :style="{ 'grid-column': `span ${actualWidth}`}">
    <div class="img"></div>
    <div class="text-container">
      <h2>Heading {{ componentModel.id }}</h2>
      <p>{{ (actualWidth/12*100).toFixed(2) }}% wide</p>
      <p v-if="componentModel.extraText">More text!<br/>..and even more text</p>
    </div>
    
    <div class="button-container" v-show="!breakpoints.isSmallOrMedium">
      <button @click="setNextWidth('down')" :disabled="widthModifier == 2">Narrow</button>
      <button @click="setNextWidth('up')" :disabled="widthModifier == 12">Wide</button>
    </div>
  </div>
</template>

<script>
import BreakpointHelper from '../utilities/breakpoint-helper.vue'

export default {
  name: 'PromoBox',
  extends: BreakpointHelper,
  props: {
    propItem: Object
  },
  data: () => ({
    componentModel: {
      extraText: false,
      widthModifier: 0,
    }
  }),
  computed: {
    widthModifier() { return this.componentModel.widthModifier; },
    actualWidth() {

      if (this.widthModifier === 12) return this.widthModifier;

      if (this.breakpoints.isSmall) return 12;

      if (this.breakpoints.isMedium) return 6;

      return this.widthModifier;
    },
    itemCssClass() {
      return 'item item--' + this.actualWidth;
    }
  },
  methods: {
    setNextWidth(direction) {
      direction === 'up' ? this.componentModel.widthModifier++ : this.componentModel.widthModifier--;
    }
  },
  created() {
    Object.assign(this.componentModel, this.propItem);
  }
}
</script>

<style scoped>
.item {
  background: lightblue;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.item .text-container {
  padding: 5px 0 5px 0;
  margin-bottom: auto;
}

.item .img 
{
  background-image: url('https://www.fillmurray.com/300/200');
  width: 100%;
  height: 75px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.item .button-container {
  margin-top: 10px;
  align-self: flex-end;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.item .button-container button {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  align-content: space-around;
}

.item.item--1 .button-container, .item.item--2 .button-container {
  display: block;
}

.item.item--1 .button-container button, .item.item--2 .button-container button {
  width: 100%;
  margin-top: 5px;
}

.item .button-container button:hover {
  background: lightblue;
}
</style>
