import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const getClosebyAliveCellCount = (fieldSize, cells, cell) => cells.filter(x => 
    x.alive && cell.id !== x.id 
    && (Math.abs(x.y - cell.y) < 2 || (cell.y == 0 || cell.y == fieldSize.y) && Math.abs(x.y - cell.y) == fieldSize.y)
    && (Math.abs(x.x - cell.x) < 2 || (cell.x == 0 || cell.x == fieldSize.x) && Math.abs(x.x - cell.x) == fieldSize.x)
).length;

export default {
    namespaced: true,
    state: {
        fieldSize: {
            x: 39,
            y: 15
        },
        generation: 1,
        cells: []
    },
    mutations: {
        updateCell(state, cell) {
            let currCell = state.cells.find(x => x.id === cell.id);
            Object.assign(currCell, cell);
        }
    },
    actions: {
        resize({ state }, fieldSize) {
            state.fieldSize = fieldSize;
        },
        populateCells({ state }, preset) {
            state.generation = 1;
            state.cells = [];

            for (let y = 0; y <= state.fieldSize.y; y++) {
                for (let x = 0; x <= state.fieldSize.x; x++) {
                    state.cells.push({
                        id : state.cells.length + 1,
                        x: x,
                        y: y,
                        alive: preset && 
                            (
                                (y === 7 && x >= 15 && x <= 18 || y >= 6 && y <= 8 && x === 14)
                                ||
                                (y === 7 && x >= 22 && x <= 25 || y >= 6 && y <= 8 && x === 26)
                            )
                    });
                }
            }
        },
        processGeneration({ state }) {

            let newCells = [];

            let currentRowIndex = -1;
            let closeRows = [];
            const getCloseRows = (cells, rowIndex) => cells.filter(x => 
                   x.y === rowIndex
                || x.y == (rowIndex - 1 >= 0 ? rowIndex - 1 : state.fieldSize.x)
                || x.y <= (rowIndex + 1 <= state.fieldSize.y ? rowIndex + 1 : 0)
            );

            for (let i = 0; i < state.cells.length; i++) {

                if (currentRowIndex !== state.cells[i].y) {
                    currentRowIndex = state.cells[i].y;
                    closeRows = getCloseRows(state.cells, currentRowIndex);
                }

                const closebyAliveCellCount = getClosebyAliveCellCount(state.fieldSize, closeRows, state.cells[i]);
                const oldState = state.cells[i].alive;

                if (!oldState && closebyAliveCellCount === 3) {
                    newCells.push({ index: i, alive: true})
                    continue;
                }

                if (!oldState || closebyAliveCellCount === 2 || closebyAliveCellCount === 3) continue;
                newCells.push({ index: i, alive: false})
            }

            for (let i = 0; i < newCells.length; i++) {
                state.cells[newCells[i].index].alive = newCells[i].alive;
            }

            state.generation++;
        }
    },
    getters: {
        cells: state => state.cells,
        fieldSize: state => state.fieldSize,
        generation: state => state.generation,
    }
}