import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import GolStore from './game-of-life-store';
import MathStore from './math-store';

const mainStore = {
  namespaced: true,
  state: {
    breakpoints: {}
  },
  mutations: {
    setBreakpoints (state, breakpoints) {
      state.breakpoints = breakpoints;
    }
  },
  getters: {
    bp: state => ({ 
      isSmall: state.breakpoints.isSmall,
      isMedium: state.breakpoints.isMedium,
      isLarge: state.breakpoints.isLarge,
      isXlarge: state.breakpoints.isXlarge,
    })
  }
};

export default new Vuex.Store({
  modules: {
    main: mainStore,
    gol: GolStore,
    math: MathStore
  },
  
})