<template>
    <form action="/" @submit.prevent="correct">
        <div class="container">
            <div>
                <h1 style="text-align: center; word-break: break-all;">Multiplikationstabellerna v2</h1>

                <button tabindex="0" :disabled="locked || isCorrecting" @click="populateCards" class="button" style="margin-bottom: 5px;">Skapa</button>
                <button tabindex="1000" type="submit" :disabled="!locked || isCorrecting" @click="correct" class="button" style="float: right; margin-bottom: 5px;">Rätta</button>

                <div v-if="isCorrecting || isCorrected">
                    <strong>Antal rätt:</strong> {{ Math.max(0, questions.filter(x => x.isCorrect).length - reveals) }} av {{ questions.length }}
                </div>
                <div v-if="reveals">
                    <strong>Antal ? använda:</strong> {{ reveals }}
                </div>
            </div>

            <div class="card-container">
                <question-card v-for="question in questions" :key="question.id" :prop-question="question" @correct="correct" />
            </div>

            <div v-if="locked & !isCorrecting" style="margin-top: 20px">
                <button tabindex="999" type="submit" @click="correct" class="button">Rätta</button>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from "vuex";
import QuestionCard from '../components/QuestionCard.vue'

export default {
    name: 'MathPractice',
    components: {
        QuestionCard
    },
    computed: {
        ...mapGetters('math',[
            'questions',
            'locked',
            'isCorrecting',
            'isCorrected',
            'reveals'
        ])
    },
    methods: {
        correct() {
            this.$store.commit('math/setIsCorrected', true);
            this.$store.commit('math/setLock', false);
        },
        populateCards() {
            this.$store.commit('math/setIsCorrected', false);
            this.$store.commit('math/setLock', true);
            this.$store.dispatch('math/populateQuestions');
        }
    }
}
</script>

<style scoped>
    .card-container {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(auto-fill);
        grid-row-gap: .5em;
        grid-column-gap: .5em;
    }
</style>
