<template>
  <nav>
    <v-breakpoint v-model="breakpoints"></v-breakpoint>
    <ul>
      <li><router-link to="/">Math</router-link></li>
      <li><router-link to="/math-old">Math old</router-link></li>
      <li><router-link to="/flex">Flexercise</router-link></li>
      <li><router-link to="/game-of-life">Game of life</router-link></li>
    </ul>
  </nav>
</template>

<script>
import { VBreakpoint, Model } from 'vue-breakpoint-component'

export default {
  name: 'PageHeader',
  components: {
    VBreakpoint
  },
  data: () => ({
    breakpoints: new Model(),
  }),
  watch: {
    breakpoints: {
      handler() {
        this.$store.commit('main/setBreakpoints', { 
          isSmall: this.breakpoints.breakpoint === 'no-match' || this.breakpoints.isSmall,
          isMedium: this.breakpoints.isMedium,
          isLarge: this.breakpoints.isLarge,
          isXlarge: this.breakpoints.isXlarge,
        });
      },
      deep: true
    },
  },
}
</script>

<style scoped>
  nav {
    padding: 15px 0;
  }

  nav ul {
    padding: 0 15px;
    list-style: none;
  }

  nav ul li {
    display: inline-block;
    margin-right: 15px;
  }

  nav ul li a, nav ul li a:visited {
    color: black;
  }

  nav ul li a.router-link-exact-active {
    font-weight: bold;
  }
</style>
