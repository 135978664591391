import Vue from 'vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Flexercise from '../views/Flexercise.vue'
import MathPractice from '../views/MathPractice.vue'
import MathPractice2 from '../views/MathPractice2.vue'
import GameOfLife from '../views/GameOfLife.vue'

export default new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
      { path: '/', component: MathPractice2 },
      { path: '/math-old', component: MathPractice },
      { path: '/flex', component: Flexercise },
      { path: '/game-of-life', component: GameOfLife }
    ]
  });