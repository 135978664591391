<template>
  <div id="app">
    <page-header></page-header>
    <div id="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>

  body { margin: 0; }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    max-width: 1000px;
    margin: 0 auto;
  }

  #main {
    padding: 0 15px;
  }
    
  .button {
    background-color: #008CBA;
    color: white;
    border: none;
    text-align: center;
    cursor: pointer;
  }

  .button, select {
    padding: 15px 32px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .button:disabled{
      background: gray;
      cursor: not-allowed;
  }

  a, a:visited {
    color: blue;
  }
</style>