<template>
  <div>
    <h1>Game of life</h1>

    <button :disabled="!cells.length || running || !cells.find(x => x.alive)" class="button" @click="start">Start</button>
    <button :disabled="!cells.length || running || !cells.find(x => x.alive)" class="button" @click="step">Step</button>
    <button :disabled="!running" class="button" @click="pause">Pause</button>
    <button :disabled="running" class="button" @click="clear(false)">New (blank)</button>
    <button :disabled="running" class="button" @click="clear(true)">New (with preset)</button>

    <div>
      <select v-model="delay">
        <option disabled value="0" selected>Select delay</option>
        <option v-for="delay in delays" :key="delay" :value="delay">{{ delay }} ms</option>
      </select>
    </div>

    <br/><strong>Size</strong>
    <div>
      <button :disabled="running" class="button" @click="resize(14, 14)">Small (15 x 15)</button>
      <button :disabled="running" class="button" @click="resize(39, 15)">Medium (default, 40 x 16)</button>
      <button :disabled="running" class="button" @click="resize(49, 49)">Large (50 x 50)</button>
    </div>

    <strong>Current generation:</strong> {{ generation }}

    <div v-if="cells.length" class="cell-board" :style="{ 'grid-template-columns': `repeat(${fieldSize.x + 1}, 1fr)` }">
      <game-cell v-for="cell in cells" :key="cell.id" :prop-cell="cell" />
    </div>

    <p>
      <a href="https://sv.wikipedia.org/wiki/Game_of_Life" target="_blank">Wikipedia</a>
    </p>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import GameCell from '../components/GameOfLife/GameCell.vue'

  export default {
    name: 'GameOfLife',
    data: () => ({
        running: false,
        delay: 0,
        delays: [50, 100, 250, 500 , 1000]
    }),
    components: { GameCell },
    computed: {
      ...mapGetters('gol', ['cells', 'fieldSize', 'generation'])
    },
    watch: {
      generation() {
        if (this.running) {
          if (!this.cells.find(x => x.alive)) {
            this.running = false;

            return;
          }
          setTimeout(() => this.processGeneration(), this.delay || 50);
        }
      }
    },
    created() {
      if (!this.cells.length) this.clear(true);
    },
    methods: {
      processGeneration() {
        this.$store.dispatch('gol/processGeneration');
      },
      clear(preset) {
        this.$store.dispatch('gol/populateCells', preset);
      },
      resize(x, y) {
        this.$store.dispatch('gol/resize', { x, y });
        this.$store.dispatch('gol/populateCells');
      },
      start() {
        this.running = true;
        this.processGeneration();
      },
      step() {
        this.processGeneration();
      },
      pause() {
        this.running = false;
      }
    }
}
</script>

<style scoped>
  .cell-board {
    margin-top: 15px;
    border: 1px solid black;
    padding: 2px;
    display: grid;
    grid-template-rows: repeat(auto-fill);
    grid-row-gap: 2px;
    grid-column-gap: 2px;
  }
</style>