<template>
    <div class="container">
        <div v-if="availableTables.length">
            <div>
                <label for="select-table">Välj tabell</label>
                <select v-model="selectedTable" id="select-table">
                    <option v-for="number in availableTables" :key="number" :value="number">{{ number }}</option>
                </select>
                
                <button v-if="replies.find(x => !isNaN(parseInt(x)))" class="button" @click="clearAnswers">Starta om</button>
            </div>

            <div>
                Bildkategori
                <select v-model="imageCategory">
                    <option value="cat">Katter</option>
                    <option value="cute-animals">Söta djur</option>
                    <option value="goat">Getter</option>
                    <option value="serengeti">Serengeti</option>
                </select>
            </div>


            <div v-show="!reward" v-for="n in this.questions" :key="n" class="card">
                <label>{{ n }} * {{ selectedTable }}</label>
                <input 
                    :disabled="reward"
                    type="number"
                    v-model="replies[n-1]"
                    :class=" { 
                        correct: !!replies[n-1] && parseInt(replies[n-1]) === (n * selectedTable),
                        incorrect: !!replies[n-1] && parseInt(replies[n-1]) !== (n * selectedTable)
                    } " />
            </div>
        </div>
        <div v-else>
            <h2>Grattis du klarade alla tabeller!!</h2>
        </div>
        <img v-if="reward" onload="window.scrollTo(0, document.body.scrollHeight)" :src="rewardSrc" />
    </div>
</template>

<script>

export default {
    name: 'MathPractice2',
    data() {
        return {
            availableTables: [2, 3, 4, 5, 6, 7, 8, 9],
            questions: 10,
            imageCategory: 'cat',
            rewardSrc: '',
            reward: false,
            hasSeenReward: false,
            replies: [],
            selectedTable: 2,
        }
    },
    watch: {
        noCorrectAnswers() {
            if (this.noCorrectAnswers === this.questions && !this.hasSeenReward) {
                this.toggleReward();
                this.availableTables = this.availableTables.filter(x => x!== this.selectedTable);
                if (this.availableTables.length) {
                    this.selectedTable = this.availableTables[0];
                }
            }
        }
    },
    computed: {
        noCorrectAnswers() {
            let x = 0;
            for(let i = 0; i < this.questions; i++) {
                if (isNaN(parseInt(this.replies[i]))) continue;

                if ((i+1) * this.selectedTable === parseInt(this.replies[i])) x++;
            }
            return x;
        }
    },
    methods: {
        clearAnswers() {
            this.reward = false;
            this.hasSeenReward = false;
            this.replies = Array(this.questions);
        }, 
        toggleReward() {
            this.reward = !this.reward;
            this.rewardSrc = this.reward ? `https://source.unsplash.com/320x320?${this.imageCategory}?x=` + encodeURIComponent(new Date().toTimeString()) : '';
        }
    },
    mounted() {
        this.clearAnswers();
    }
}
</script>

<style scoped>

    .container {
        max-width: 400px;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    .card {
        border-radius: 5px;
        border: 1px solid #c0c0c0;
        padding: 15px;
        margin-bottom: 20px;
    }

    label, select {
        margin-right: 10px;
    }

    input {
        padding: 15px;
        font-size: 16px;
        max-width: 100px;
        text-align: center;
        border: 1px solid gray;
    }

    input.correct {
        background: lightgreen;
    }

    input.incorrect {
        border: 1px solid lightcoral;
    }
</style>
