<script>
    import { mapGetters } from "vuex";
    export default {
        computed: {
            ...mapGetters('main', ['bp']),
            breakpoints() {
                return {
                    isSmall: this.bp.isSmall,
                    isSmallOrMedium: this.bp.isSmall || this.bp.isMedium,
                    isMedium: this.bp.isMedium,
                    isLarge: this.bp.isLarge || this.bp.isXlarge
                }
            }
        }
    }
</script>