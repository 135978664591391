import Vue from 'vue'

import PageHeader from './components/PageHeader.vue'
import App from './App.vue'
import store from './misc/store'
import router from './misc/router'

import VueRouter from 'vue-router';
Vue.use(VueRouter);

Vue.config.productionTip = false

Vue.component('PageHeader', PageHeader);

new Vue({
  data: {
    currentRoute: window.location.pathname
  },
  router: router,
  render (h) { return h(App) },
  store: store,
}).$mount('#app')


if ('serviceWorker' in navigator) {

  navigator.serviceWorker.getRegistrations().then(function(registrations) {

  for(let registration of registrations) {

          registration.unregister()

  }}).catch(function(err) {

      console.log('Service Worker registration failed: ', err);

  });
}