<template>
  <div>
    <div class="container">
      <PromoBox v-for="promoItem in promoItems"
                :key="promoItem.id"
                :prop-item="promoItem" />
    </div>
  </div>
</template>

<script>

import PromoBox from '../components/PromoBox.vue'

export default {
  name: 'App',
  components: {
    PromoBox,
  },
  data: () => ({
      promoItems: [],
  }),
  created() {
    const getWidth = (index) => {

      switch (index)
      {
        case 3: return 3;
        case 4: return 3;
        case 5: return 9;
        case 6:
        case 7:
        case 8:
          return 3;
        case 9:
        case 10: return 6;
        case 11: return 6;
        case 12: return 12;
      }

      return 3;
    };

    for (var i = 1; i <= 12; i++) {
      this.promoItems.push({
        id: i,
        extraText: i == 2 || i == 8,
        widthModifier: getWidth(i)
      });
    }
  }
}
</script>

<style scoped>
  .container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(auto-fill);
    grid-row-gap: .5em;
    grid-column-gap: .5em;
  }
</style>
