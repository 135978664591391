<template>
    <div :class="classList" :style="{ 'grid-column': `span ${span}`}">
        
        <label>{{ question.text }}</label>

        <div v-if="question.isCorrected && !question.isCorrect" class="q-card green">
            <p style="min-width: 200px; font-size: 16px;">
                Rätt&nbsp;svar:&nbsp;{{ question.correctAnswer }}<br/>
                Ditt&nbsp;svar:&nbsp;{{ question.answer || '-' }}
            <p/>
        </div>
        
        <div v-if="!question.isCorrected || question.isCorrect" style="text-align: center;">
            <input 
                :tabindex="question.index"
                :disabled="isCorrected || revealed || isCorrecting"
                type="number"
                v-model="question.answer"
                v-on:keyup.enter="onEnter" />

                <button v-if="!question.isCorrected && !revealed && !isCorrected && !isCorrecting && remainingReveals"
                        @click="reveal"
                        tabindex="-1">?</button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import BreakpointHelper from '../utilities/breakpoint-helper.vue'

    export default {
        name: 'QuestionCard',
        extends: BreakpointHelper,
        data: () => ({
            question: { 
                answer: '' ,
                isCorrected: false,
                isCorrect: false,
            },
            revealed: false
        }),
        props: {
            propQuestion: Object
        },
        computed: {
            ...mapGetters('math',[
                'isCorrected',
                'isCorrecting',
                'reveals',
                'questions',
                'reveals'
            ]),
            span() {
                if (this.breakpoints.isMedium) return 6;
                return this.breakpoints.isSmall ? 12 : 4;
            },
            classList() {
                return {
                    'q-card': true,
                    green: (this.question.isCorrected && this.question.isCorrect) || this.revealed,
                    red: this.question.isCorrected && !this.question.isCorrect
                }
            },
            remainingReveals() {
                return this.reveals < 3;
            },
            isLast() {
                return this.questions.length - 1 === this.questions.findIndex(x => x.id === this.question.id);
            }
        },
        created() {
            Object.assign(this.question, this.propQuestion);
        },
        watch: {
            propQuestion: {
                handler() {
                    Object.assign(this.question, this.propQuestion);
                },
                deep: true
            },
            question: {
                handler() {
                    this.$store.commit('math/updateQuestion', this.question);
                },
                deep: true
            },
        },
        methods: {
            onEnter() {
                if (!this.isLast) return;
                this.$emit('correct');
            },
            reveal() {
                this.$store.dispatch('math/reveal');
                this.question.answer = this.question.correctAnswer;
                this.revealed = true;
            }
        }
    }
</script>

<style scoped>

    .q-card {
        background-color: #f8f9fa;
        font-size: 24px;
        padding: 5px;
        position: relative;
    }

    .q-card.red {
        background: #ff4f67;
    }

    .q-card.green {
        background: #7eba65;
    }

    .q-card > label {
        display: block;
        text-align: center;
        margin-bottom: 10px
    }

    .q-card input {
        max-width: 100px;
        font-size: 22px;
        text-align: center;
    }

    button {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
    }
</style>