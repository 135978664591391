<template>
  <div @click="click" :class="classList">
  </div>
</template>

<script>
  export default {
    name: 'GameCell',
    data: () => ({
        cell: { 
          id: 0,
          alive: false
        }
    }),
    computed: {
      classList() {
        return {
          'cell': true,
          'cell--alive': this.cell.alive
        }
      }
    },
    methods: {
      click() {
        this.cell.alive = !this.cell.alive;
        this.$store.commit('gol/updateCell', this.cell);
      }
    },
    props: {
      propCell: Object
    },
    watch: {
      propCell: {
        handler() {
          Object.assign(this.cell, this.propCell);
        },
        deep: true
      }
    },
    mounted() {
      Object.assign(this.cell, this.propCell);
    }
}
</script>

<style scoped>
  .cell {
    position: relative;
    background: lightblue;
    font-size: 6px;
    aspect-ratio: 1;
    cursor: pointer;
  }

  .cell.cell--alive {
    background: green;
  }

  .cell:hover::before {
    content: '';
    background: black;
    display: block;
    position: absolute;
    top: -5px;
    right: -5px;
    left: -5px;
    bottom: -5px;
    z-index: 1;
  }

  .cell.cell--alive:hover::before {
    border: 1px solid black;
    background: green;
  }
</style>