import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const theSize = 12;

export default {
    namespaced: true,
    state: {
        questions: [],
        locked: false,
        isCorrected: false,
        isCorrecting: false,
        reveals: 0
    },
    mutations: {
        updateQuestion(state, question) {
            const q = state.questions.find(x => x.id === question.id);
            const index = state.questions.indexOf(q);
            state.questions[index] = question;
        },
        setLock(state, locked) {
            state.locked = locked;
        },
        setIsCorrected(state, isCorrected) {

            if (isCorrected)
            {
                state.isCorrecting = true;
                const task = (i, q) => {
                    setTimeout(function() {
                        q.isCorrected = true;
                        q.isCorrect = q.correctAnswer == q.answer;
                        state.isCorrected = i === theSize - 1;
                        state.isCorrecting = !state.isCorrected;
                    }, 300 * i);
                }

                for (var i = 0; i < state.questions.length; i++) {
                    const q = state.questions[i];
                    task(i, q);
                }
            } else {                
                state.isCorrected = false;
            }
        }
    },
    actions: {
        reveal({ state }) {
            state.reveals++;
        },
        populateQuestions({ state }) {
            state.reveals = 0;
            const questions = [];
            const time = new Date().getTime();

            for (var i = 1; i <= 9; i++) {
                for (var j = 1; j <= 10; j++) {
                    questions.push({
                        answer: '',
                        correctAnswer: `${i*j}`,
                        id: `${i}${j}${time}`,
                        index: 0,
                        text: `${i} * ${j}`,
                        revealed: false,
                        isCorrect: false,
                        isCorrected: false
                    });
                }
            }

            const shuffle = function(a) {
                for (let i = a.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [a[i], a[j]] = [a[j], a[i]];
                }
                return a;
            };
            state.questions = shuffle(questions).slice(0, theSize);

            for (var y = 1; y <= state.questions.length; y++) {
                state.questions[y-1].index = y;
            }
        }
    },
    getters: {
        questions: state => state.questions,
        locked: state => state.locked,
        isCorrected: state => state.isCorrected,
        isCorrecting: state => state.isCorrecting,
        reveals: state => state.reveals
    }
}